<template>
    <div class="cardPwdModify">
        <div class="left-box">
            <div class="title">会员信息</div>
            <div class="tr-li card-tr">
                <div class="lable-txt">卡号：</div>
                <div class="from-input">
                    <div class="card-no"><input-pattern keyBoard pattern="code" placeholder="请输入卡号或读卡" v-model="cardNo"  @keyup.enter="selectReadCard()" :maxlength="20"/><i class="iconfont icon-sousuo" @click="selectReadCard()"></i></div> 
                    <div class="read-card" @click="readCard()">读卡</div>    
                </div>
            </div>
            <div class="info-box">
                <div class="title">客户资料信息</div>
                <div class="tr-li">
                    <div class="lable-txt">会员等级：</div>
                    <div class="from-input"><input type="text" :value="memberInfo?.Bestech_Card_KindName" readonly/></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">客户姓名：</div>
                    <div class="from-input"><input type="text" :value="memberInfo?.MemberName"  readonly/></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">手机号：</div>
                    <div class="from-input"><input type="text" :value="memberInfo?.Mobile" readonly/></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">生日：</div>
                    <div class="from-input"><input type="text" :value="memberInfo?.Birthday" readonly/></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">性别：</div>
                    <div class="from-input"><input type="text" :value="{0:'男',1:'女'}[memberInfo?.Gender]" readonly/></div>
                </div>
                <div class="title">卡资产信息</div>
                <div class="tr-li">
                    <div class="lable-txt">余额：</div>
                    <div class="from-input"><input class="text-right" type="text" :value="memberInfo?.Card_Money" readonly/>
                        <p class="describe" :style="memberInfo?'':'visibility: hidden'">其中赠送金额{{memberInfo?.Gift_Money}}元</p>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">积分：</div>
                    <div class="from-input"><input class="text-right" type="text" :value="memberInfo?.Card_PilePoint" readonly/>
                        <p class="describe"></p>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">券：</div>
                    <div class="from-input ticket" @click="ticketClick()"><input class="text-right" :value="(memberInfo?.Coupons?.length||'0')+'张'" type="text" readonly/><i class="iconfont">></i>
                        <p class="describe"></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-box">
            <div class="scroll-box">
                <div class="tr-li">
                    <div class="lable-txt">旧密码：</div>
                    <div class="from-input">
                        <input type="password" v-model="oldPwd" keyBoard/>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">新密码：</div>
                    <div class="from-input">
                        <input type="password" v-model="newPwd" keyBoard/>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">确认密码：</div>
                    <div class="from-input">
                        <input type="password" v-model="newPwd2" keyBoard/>
                    </div>
                </div>
            </div>
            <div class="footer-box">
                <button class="btn" @click="back()">返回</button>
                <button class="btn selected" @click="cardResetPwd()">密码重置(恢复默认)</button>
                <button class="btn selected" @click="cardPwdSet()">确定修改</button>
            </div>
        </div>
        <!-- 券列表 -->
        <modal-load :isShow="ticketShow">
            <ticket-model :isShow="ticketShow" @closeModel="ticketShow=false" :Coupons="memberInfo?.Coupons"></ticket-model>
        </modal-load> 
         <!-- 选择卡信息 -->
        <modal-load :isShow="cardMemberShow">
            <select-card-member-info :isShow="cardMemberShow" @closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo"></select-card-member-info>
        </modal-load>
    </div>
</template>

<script>
import { ticketModel,selectCardMemberInfo } from '../model'

/**修改密码 */
export default {
    name:'cardPwdModify',
    components:{ticketModel,selectCardMemberInfo},
    data(){
        return {
            /**卡号 */
            cardNo:'',
            /**卡id */
            card_AutoID:'',
             /**会员信息 */
            memberInfo:undefined,
            /**券列表 */
            ticketShow:false,
            /**选择卡信息 */
            cardMemberShow:false,
            /**多个卡信息 */
            cardMemberList:[],
            /**旧密码 */
            oldPwd:'',
            /*新密码 */
            newPwd:'',
            /*确认密码 */
            newPwd2:''
        }
    },
    watch:{
        cardNo(){
            this.memberInfo=null;
        }
    },
    methods:{
        /**退出 */
        back(){
            this.$emit("close");
        },
         /*刷新当前页面 */
        reload() {
            this.$emit("reload");
        },
        ticketClick(){
            if(this.memberInfo){
                this.ticketShow = true;
            }
        },
        /**点击查询图标读卡   m1卡 Card_AutoID 卡id,Card_SN 内卡号 */
        selectReadCard(Card_AutoID,Card_SN){
            if(!this.cardNo && !Card_SN){
                this.$message.warning('请填写会员卡号')
                return
            }
            const loading = this.$loading({
                text: "读取会员信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",{
                User_ID:this.userInfo?.User_ID,
                DynamicNo:this.cardNo,
                Card_AutoID:Card_AutoID,
                Card_SN:Card_SN
            }).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode==0){
                    if(data.ResponseBody.length>1){
                        this.cardMemberList=data.ResponseBody;
                        this.cardMemberShow=true;
                    }else{
                        this.selectCardInfo(data.ResponseBody[0])
                    }
                }else{
                    this.memberInfo=null,
                    this.$message.error("会员信息读取失败："+data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.memberInfo=null,
                this.$message.error('会员信息读取失败：'+e);
                console.log('会员信息读取失败：'+e);
            })
        },
        /**点击 读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.cardNo=d.data.cardNo;
                        this.selectReadCard(d.data.cardID,d.data.snr);
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        /**多个卡信息时选择一个 */
        selectCardInfo(data){
            this.cardMemberShow=false;
            this.cardNo=data.Card_No;
            this.$nextTick(()=>{
                this.memberInfo = data;
                this.card_AutoID = this.memberInfo?.Bestech_Card_AutoID;
            })
        },
        /**密码修改 */
        cardPwdSet(){
            if(!this.cardNo){
                 this.$message.warning('请先输入卡号');
                 return
            }
            if(!this.memberInfo){
                 this.$message.warning('请先获取会员卡数据');
                 return
            }
            if(this.oldPwd==""){
                this.$message.warning('请输入旧密码！');
                return;
            }
            if(this.newPwd==""){
                this.$message.warning('请输入新密码！');
                return;
            }
            if(this.newPwd2==""){
                this.$message.warning('请输入确认密码！');
                return;
            }
            if(this.newPwd!=this.newPwd2){
                this.$message.warning('新密码与确认密码输入不一致！');
                return;
            }

            const loading = this.$loading({
                text: "密码修改中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo=this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.CardPwdSet",{
                User_ID:userInfo?.User_ID,
                Card_No:this.cardNo,//外卡号
                Card_AutoID:this.memberInfo?.Bestech_Card_AutoID,//卡主键
                Operate_User:userInfo?.Login_Name,//操作员
                ResetPwd:0,//是否重置密码 0否 1是 重置为 000000
                OldPwd:this.oldPwd,//旧密码
                NewPwd:this.newPwd//新密码
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error("密码修改失败："+d.ResponseHeader.ResultDesc);
                    return;
                }
                this.$message.success("密码修改完成");
                this.reload();
            }).catch((e)=>{
                loading.close();
                this.$message.error('密码修改失败：'+e);
                console.log('密码修改失败：'+e);
            })
        },
        /**重置密码 */
        cardResetPwd(){
            if(!this.cardNo){
                 this.$message.warning('请先输入卡号');
                 return
            }
            if(!this.memberInfo){
                 this.$message.warning('请先获取会员卡数据');
                 return
            }
            const loading = this.$loading({
                text: "密码重置中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo=this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.CardPwdSet",{
                User_ID:userInfo?.User_ID,
                Card_No:this.cardNo,//外卡号
                Card_AutoID:this.memberInfo?.Bestech_Card_AutoID,//卡主键
                Operate_User:userInfo?.Login_Name,//操作员
                ResetPwd:1,//是否重置密码 0否 1是 重置为 000000
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error("密码重置失败："+d.ResponseHeader.ResultDesc);
                    return;
                }
                this.$message.success("密码重置完成");
                this.reload();
            }).catch((e)=>{
                loading.close();
                this.$message.error('密码重置失败：'+e);
                console.log('密码重置失败：'+e);
            })
        }
    }
}
</script>

<style lang="scss">
@import "./cardPwdModify.scss";
</style>